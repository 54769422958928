/* eslint-disable id-length */
// Only flags specified here will be pulled from switchboard/hivemind
module.exports = {
  SECUI_MSSL_MAINTENANCE: 'secui-mssl-maintenance',
  SECUI_SETUP_COLOR_V1: 'secui_setup_color_v1',
  SECUI_BACKUPS_NOTIFICATIONS: 'secui_backups_notifications',
  SECUI_SUPPORT_MGMT_1: 'secui_support_mgmt_1',
  SECUI_MSSL_APIV2_HEALTHCHECK: 'secui_mssl_apiv2_healthcheck',
  SECUI_MSSL_APIV2_LOGS: 'secui_mssl_apiv2_logs',
  SECUI_MSSL_ONETIME: 'secui_mssl_onetime',
  SECUI_MSSL_SECURE_CREDS: 'secui_mssl_secure_creds',
  SECUI_MSSL_SETUP_V2: 'secui_mssl_setup_v2',
  SECUI_BACKUPS_NEW_UI_INTERNAL_MGMT: 'secui_backups_new_ui_internal_mgmt',
  SECUI_POLL_RESTORE_PROCESS: 'secui_poll_restore_process',
  SECUI_BACKUPS_MULTIPLE_DATABASES: 'secui_backups_multiple_databases',
  SECUI_BACKUPS_ERRORCODES: 'secui_backups_errorcodes',
  SECUI_OFFLINE_MODE: 'secui_offline_mode',
  SECUI_SETUP_MODAL_BACKUP_ERROR_WS_6662:
    'secui_setup_modal_backup_error_WS_6662',
  SECUI_GLOBAL_SUPPORT_BUTTON: 'secui_global_support_button',
  SECUI_PRODUCTS_API_MONITORS_LIST_SITES:
    'secui_products_api_monitors_list_sites',
  SECUI_PRODUCTS_API_MONITORS_V2: 'secui_products_api_monitors_v2',
  SECUI_PRODUCTS_API_WAF_V2: 'secui_products_api_waf_v2',
  SECUI_SUPPORT_V2: 'secui_support_v2',
  SECUI_SETUP_PROVISIONING_TOGGLE: 'secui_setup_provisioning_toggle',
  SECUI_MONITORS_V2_WEEKLY_STATS_ENABLED:
    'secui_monitors_v2_weekly_stats_enabled',
  SECUI_MONITORS_V2_TRUST_SEALS_ENABLED:
    'secui_monitors_v2_trust_seals_enabled',
  SECUI_MY_SITES_V2: 'secui_my_sites_v2',
  SECUI_REFUND_PAGE: 'secui_refund_page',
  SECUI_WAF_CDN_UPGRADE: 'secui_waf_cdn_upgrade',
  SECUI_WAFV2_PERFORMANCE_CACHING_LEVEL:
    'secui_wafv2_performance_caching_level',
  SECUI_WAFV2_AGGRESSIVE_BOT_FILTERING: 'secui_wafv2_aggressive_bot_filtering',
  SECUI_MSSL_EXISTING_DOMAIN: 'secui_mssl_existing_domains',
  SECUI_WSS_NUX_ONBOARDING: 'secui_wss_nux_onboardingv2',
  SECUI_MY_SITES_V2_PLIDS: 'secui_my_sites_v2_plids',
  SECUI_TRIAL_VERBIAGE_DISABLED: 'secui_trial_verbiage_disabled',
  SECUI_DV_SSL_NUX_ONBOARDING: 'secui_dv_ssl_nux_onboarding',
  SECUI_MSSL_NUX_ONBOARDING: 'secui_mssl_nux_onboarding',
  SECUI_SSLOTS_NUX_ONBOARDING: 'secui_sslots_nux_onboarding',
  SECUI_TROUBLESHOOTER_WIDGET: 'secui_troubleshooter_widget',
  SECUI_SECURITY_OPTIMIZER_WIDGET: 'secui_security_optimizer_widget',
  SECUI_REPORT_CERTIFICATE: 'secui_report_certificate',
};
