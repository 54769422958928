// flags.js
import { useContext, useEffect } from 'react';
import Router from 'next/router';

import { isImpersonation } from '@/common/helpers';

import flags from '../common/flags';
import { ConfigContext } from './config';

const useFlag = (key, opts) => {
  const ctx = useContext(ConfigContext);

  if (!opts || !opts.rawValue) {
    return ctx && (ctx[key] === true || ctx[key] === 'on');
  }

  return ctx && ctx[key];
};

const withFlagProtected = (Component, flag) => {
  const WrappedComponent = (props) => {
    const isEnabled = useFlag(flag);

    useEffect(() => {
      if (isEnabled) {
        return;
      }

      Router.push('/404');
    }, [isEnabled]);

    if (!isEnabled) {
      return null;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};
const withFlagOrE2sProtected = (Component, flag) => {
  const WrappedComponent = (props) => {
    const isEnabled = useFlag(flag) || isImpersonation();

    useEffect(() => {
      if (isEnabled) {
        return;
      }

      Router.push('/404');
    }, [isEnabled]);

    if (!isEnabled) {
      return null;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};

module.exports = {
  ...flags,
  useFlag,
  withFlagProtected,
  withFlagOrE2sProtected,
};
